html {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    /* overflow: hidden; */
}

.stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1815px 592px #fff, 719px 1232px #fff, 836px 1251px #fff,
        1752px 515px #fff, 1137px 919px #fff, 1864px 558px #fff, 95px 144px #fff,
        1205px 296px #fff, 977px 675px #fff, 206px 38px #fff, 1909px 893px #fff,
        1650px 504px #fff, 1466px 956px #fff, 1627px 1361px #fff,
        1376px 1667px #fff, 1245px 1150px #fff, 1993px 1023px #fff,
        1639px 645px #fff, 1042px 1572px #fff, 373px 1976px #fff,
        823px 1668px #fff, 1790px 1988px #fff, 1892px 1592px #fff,
        542px 1557px #fff, 1084px 1577px #fff, 1421px 1657px #fff,
        85px 378px #fff, 1143px 1831px #fff, 963px 1317px #fff, 387px 325px #fff,
        44px 1715px #fff, 1324px 1639px #fff, 405px 726px #fff,
        1900px 1864px #fff, 1009px 55px #fff, 1442px 1228px #fff,
        394px 1710px #fff, 481px 758px #fff, 1204px 1331px #fff,
        1704px 1134px #fff, 210px 1081px #fff, 462px 58px #fff,
        1365px 403px #fff, 765px 207px #fff, 1974px 167px #fff,
        1264px 993px #fff, 1197px 1851px #fff, 84px 688px #fff,
        507px 1706px #fff, 1127px 1103px #fff, 295px 1225px #fff,
        1009px 797px #fff, 1598px 1718px #fff, 1581px 1749px #fff,
        1998px 710px #fff, 221px 73px #fff, 1717px 400px #fff, 769px 591px #fff,
        1319px 1289px #fff, 578px 339px #fff, 281px 1489px #fff,
        87px 1909px #fff, 145px 1232px #fff, 1857px 1504px #fff,
        1529px 1363px #fff, 48px 711px #fff, 622px 1542px #fff,
        1736px 152px #fff, 1033px 355px #fff, 539px 1073px #fff,
        852px 162px #fff, 1506px 546px #fff, 793px 1743px #fff,
        1472px 333px #fff, 987px 1466px #fff, 637px 1457px #fff,
        66px 1885px #fff, 980px 234px #fff, 1804px 1651px #fff,
        1972px 1916px #fff, 971px 1638px #fff, 1109px 462px #fff,
        1764px 799px #fff, 267px 1768px #fff, 778px 1260px #fff,
        1271px 1010px #fff, 1903px 1905px #fff, 197px 290px #fff,
        772px 1199px #fff, 535px 75px #fff, 1802px 79px #fff, 249px 730px #fff,
        1887px 896px #fff, 1612px 435px #fff, 1811px 1635px #fff,
        1389px 1434px #fff, 951px 933px #fff, 1037px 958px #fff,
        237px 355px #fff, 1385px 345px #fff, 1237px 985px #fff,
        639px 1439px #fff, 992px 1353px #fff, 1946px 358px #fff,
        481px 1623px #fff, 1749px 498px #fff, 529px 217px #fff,
        1056px 717px #fff, 1140px 903px #fff, 1983px 1891px #fff,
        1143px 812px #fff, 1111px 253px #fff, 1396px 852px #fff,
        1465px 1541px #fff, 1727px 486px #fff, 26px 1906px #fff,
        134px 1240px #fff, 269px 1664px #fff, 187px 515px #fff,
        1643px 1993px #fff, 547px 211px #fff, 624px 1386px #fff,
        1451px 447px #fff, 1157px 718px #fff, 114px 1909px #fff,
        1863px 833px #fff, 1493px 1141px #fff, 1228px 400px #fff,
        1853px 673px #fff, 1241px 854px #fff, 1154px 859px #fff,
        1727px 245px #fff, 1082px 1222px #fff, 1300px 758px #fff,
        1486px 1768px #fff, 1576px 353px #fff, 819px 1479px #fff,
        1994px 1171px #fff, 1944px 1037px #fff, 282px 1459px #fff,
        1051px 1524px #fff, 897px 1364px #fff, 796px 1444px #fff,
        644px 1442px #fff, 1464px 369px #fff, 1212px 1435px #fff,
        632px 806px #fff, 956px 1534px #fff, 1694px 1701px #fff,
        1562px 478px #fff, 577px 1966px #fff, 558px 1728px #fff,
        1937px 1472px #fff, 57px 1661px #fff, 761px 1981px #fff,
        1845px 1716px #fff, 1537px 1877px #fff, 1465px 417px #fff,
        1300px 1768px #fff, 126px 386px #fff, 1234px 203px #fff,
        855px 760px #fff, 1925px 333px #fff, 1293px 1861px #fff,
        839px 1289px #fff, 1590px 94px #fff, 1558px 723px #fff,
        1492px 974px #fff, 289px 1745px #fff, 1322px 205px #fff,
        1981px 27px #fff, 1685px 131px #fff, 1757px 912px #fff,
        1557px 899px #fff, 1256px 1764px #fff, 1740px 319px #fff,
        1219px 1828px #fff, 1734px 490px #fff, 1560px 1688px #fff,
        858px 700px #fff, 728px 809px #fff, 1837px 1142px #fff,
        642px 1589px #fff, 1535px 1302px #fff, 1185px 1211px #fff,
        952px 1950px #fff, 1227px 692px #fff, 240px 1751px #fff,
        1940px 714px #fff, 1249px 773px #fff, 919px 169px #fff,
        1632px 423px #fff, 1857px 1245px #fff, 1569px 686px #fff,
        1711px 1188px #fff, 1368px 898px #fff, 1361px 1762px #fff,
        1945px 900px #fff, 1446px 1396px #fff, 554px 1427px #fff,
        303px 1997px #fff, 179px 342px #fff, 1048px 732px #fff,
        867px 1236px #fff, 1240px 349px #fff, 1790px 421px #fff,
        189px 1990px #fff, 1140px 1602px #fff, 37px 593px #fff, 247px 869px #fff,
        1598px 463px #fff, 204px 516px #fff, 779px 1131px #fff,
        666px 1105px #fff, 581px 559px #fff, 1183px 660px #fff,
        1824px 1208px #fff, 1174px 909px #fff, 1293px 1802px #fff,
        397px 401px #fff, 555px 1168px #fff, 382px 1827px #fff,
        1037px 1024px #fff, 1246px 515px #fff, 298px 67px #fff, 1511px 79px #fff,
        1033px 753px #fff, 1897px 1083px #fff, 293px 1957px #fff,
        78px 1465px #fff, 1098px 876px #fff, 909px 1821px #fff, 591px 674px #fff,
        1926px 788px #fff, 1750px 1742px #fff, 1517px 95px #fff,
        1074px 593px #fff, 1907px 1394px #fff, 946px 700px #fff,
        1256px 1785px #fff, 302px 1398px #fff, 1298px 1667px #fff,
        1031px 933px #fff, 58px 671px #fff, 6px 1826px #fff, 613px 1785px #fff,
        951px 155px #fff, 855px 684px #fff, 593px 1940px #fff,
        1898px 1147px #fff, 573px 287px #fff, 321px 1629px #fff,
        1374px 529px #fff, 1486px 34px #fff, 1160px 546px #fff,
        567px 1892px #fff, 570px 441px #fff, 1857px 1270px #fff,
        297px 827px #fff, 1087px 1416px #fff, 687px 1487px #fff,
        188px 1530px #fff, 605px 467px #fff, 1769px 1416px #fff,
        120px 1609px #fff, 192px 1199px #fff, 314px 1065px #fff,
        294px 1191px #fff, 1265px 130px #fff, 516px 407px #fff, 58px 1703px #fff,
        1078px 1689px #fff, 1495px 317px #fff, 825px 1996px #fff,
        387px 518px #fff, 469px 1405px #fff, 792px 1577px #fff, 261px 642px #fff,
        500px 195px #fff, 1323px 1282px #fff, 1843px 394px #fff,
        1419px 1170px #fff, 1166px 203px #fff, 1392px 722px #fff,
        932px 1462px #fff, 767px 193px #fff, 1998px 105px #fff, 1005px 7px #fff,
        1620px 1138px #fff, 1156px 1622px #fff, 1304px 904px #fff,
        1984px 1852px #fff, 1767px 1971px #fff, 1391px 413px #fff,
        954px 1522px #fff, 937px 1604px #fff, 942px 211px #fff,
        1950px 1960px #fff, 1127px 538px #fff, 1446px 1923px #fff,
        1009px 806px #fff, 281px 544px #fff, 1916px 414px #fff,
        871px 1534px #fff, 1033px 12px #fff, 1549px 1130px #fff,
        1868px 224px #fff, 1281px 1930px #fff, 1996px 1433px #fff,
        1998px 1135px #fff, 1301px 627px #fff, 632px 828px #fff, 1588px 7px #fff,
        543px 1437px #fff, 403px 1421px #fff, 1835px 814px #fff,
        356px 1716px #fff, 1383px 1445px #fff, 1922px 1330px #fff,
        1340px 986px #fff, 1345px 340px #fff, 1199px 528px #fff,
        1718px 305px #fff, 339px 1034px #fff, 514px 1448px #fff,
        248px 1532px #fff, 1349px 825px #fff, 1041px 1365px #fff,
        1792px 1674px #fff, 1818px 1132px #fff, 1359px 951px #fff,
        1500px 92px #fff, 101px 2px #fff, 1755px 463px #fff, 1959px 1072px #fff,
        193px 345px #fff, 77px 314px #fff, 1534px 39px #fff, 762px 1453px #fff,
        1786px 1963px #fff, 1888px 1454px #fff, 839px 1783px #fff,
        846px 1916px #fff, 323px 266px #fff, 245px 889px #fff, 939px 1342px #fff,
        1925px 532px #fff, 873px 878px #fff, 371px 761px #fff, 900px 1064px #fff,
        1552px 209px #fff, 794px 1515px #fff, 887px 1483px #fff,
        899px 278px #fff, 1902px 383px #fff, 847px 139px #fff, 219px 338px #fff,
        1564px 511px #fff, 25px 537px #fff, 720px 391px #fff, 1019px 552px #fff,
        1611px 1636px #fff, 834px 1925px #fff, 1902px 490px #fff,
        1562px 325px #fff, 1245px 23px #fff, 304px 1467px #fff,
        441px 1584px #fff, 468px 88px #fff, 1250px 873px #fff, 1771px 918px #fff,
        1928px 492px #fff, 1899px 85px #fff, 831px 1590px #fff,
        1470px 311px #fff, 317px 418px #fff, 1781px 637px #fff,
        1441px 1545px #fff, 1385px 1152px #fff, 137px 226px #fff,
        1104px 1839px #fff, 1076px 44px #fff, 67px 1540px #fff,
        1388px 1625px #fff, 1850px 1400px #fff, 708px 286px #fff, 280px 7px #fff,
        515px 534px #fff, 1130px 337px #fff, 1869px 1559px #fff,
        1014px 1350px #fff, 1295px 956px #fff, 145px 1945px #fff,
        137px 1566px #fff, 589px 782px #fff, 1162px 1732px #fff,
        1795px 1518px #fff, 1705px 1667px #fff, 1185px 734px #fff,
        81px 31px #fff, 1526px 822px #fff, 1547px 983px #fff, 1317px 1283px #fff,
        403px 817px #fff, 1506px 1198px #fff, 1899px 1540px #fff,
        1390px 1561px #fff, 380px 767px #fff, 1110px 247px #fff,
        1078px 475px #fff, 1120px 473px #fff, 1579px 72px #fff,
        976px 1164px #fff, 1634px 1263px #fff, 1648px 519px #fff,
        1437px 1568px #fff, 1019px 203px #fff, 1385px 147px #fff,
        1975px 215px #fff, 20px 162px #fff, 646px 1998px #fff, 1908px 710px #fff,
        390px 1727px #fff, 570px 546px #fff, 34px 1991px #fff, 1037px 573px #fff,
        1585px 512px #fff, 210px 584px #fff, 606px 207px #fff,
        1385px 1082px #fff, 659px 417px #fff, 1117px 583px #fff,
        1553px 129px #fff, 1886px 1738px #fff, 1108px 1426px #fff,
        1774px 1711px #fff, 626px 1366px #fff, 881px 1836px #fff,
        5px 1096px #fff, 424px 785px #fff, 25px 261px #fff, 547px 1505px #fff,
        953px 870px #fff, 1202px 1490px #fff, 1525px 1401px #fff,
        506px 1443px #fff, 99px 633px #fff, 115px 248px #fff, 1378px 1054px #fff,
        1423px 211px #fff, 120px 1861px #fff, 1395px 338px #fff,
        794px 496px #fff, 1367px 1288px #fff, 492px 1555px #fff, 2px 1498px #fff,
        1186px 358px #fff, 236px 902px #fff, 1790px 534px #fff, 684px 376px #fff,
        605px 1824px #fff, 1192px 1220px #fff, 1565px 645px #fff,
        1933px 95px #fff, 173px 1131px #fff, 1461px 1572px #fff,
        1457px 1105px #fff, 1365px 1020px #fff, 1946px 130px #fff,
        1019px 1197px #fff, 898px 749px #fff, 1929px 852px #fff,
        1665px 703px #fff, 1086px 1684px #fff, 630px 368px #fff,
        832px 1653px #fff, 304px 291px #fff, 86px 69px #fff, 1615px 464px #fff,
        834px 1204px #fff, 1453px 1609px #fff, 62px 1831px #fff,
        1051px 431px #fff, 499px 1633px #fff, 472px 1584px #fff,
        744px 719px #fff, 1989px 155px #fff, 322px 1614px #fff,
        1957px 1370px #fff, 1726px 662px #fff, 687px 894px #fff,
        1385px 927px #fff, 1929px 315px #fff, 699px 1733px #fff,
        178px 1633px #fff, 1955px 212px #fff, 195px 1754px #fff,
        1057px 1419px #fff, 1018px 1547px #fff, 1322px 1017px #fff,
        540px 1397px #fff, 385px 694px #fff, 1024px 1011px #fff,
        1251px 384px #fff, 94px 1833px #fff, 1441px 800px #fff, 911px 351px #fff,
        1688px 504px #fff, 80px 826px #fff, 1709px 643px #fff,
        1784px 1736px #fff, 1802px 1162px #fff, 206px 1467px #fff,
        522px 1670px #fff, 108px 1445px #fff, 1931px 169px #fff,
        812px 1897px #fff, 1083px 1799px #fff, 340px 933px #fff,
        287px 1284px #fff, 573px 768px #fff, 1994px 1529px #fff,
        387px 971px #fff, 1456px 671px #fff, 1808px 1867px #fff,
        1580px 117px #fff, 129px 967px #fff, 1237px 108px #fff, 169px 884px #fff,
        257px 537px #fff, 1837px 1264px #fff, 34px 968px #fff, 375px 1511px #fff,
        882px 813px #fff, 1681px 1326px #fff, 456px 598px #fff,
        654px 1647px #fff, 929px 757px #fff, 913px 1508px #fff,
        1222px 795px #fff, 1336px 1896px #fff, 1018px 296px #fff,
        1942px 513px #fff, 272px 1524px #fff, 370px 623px #fff,
        1744px 1014px #fff, 74px 1007px #fff, 496px 506px #fff,
        1434px 1434px #fff, 1920px 1557px #fff, 1912px 695px #fff,
        1747px 1907px #fff, 640px 1508px #fff, 300px 1225px #fff,
        984px 1676px #fff, 385px 565px #fff, 523px 1613px #fff, 15px 1269px #fff,
        1757px 1478px #fff, 1534px 1065px #fff, 752px 1936px #fff,
        1269px 518px #fff, 759px 464px #fff, 560px 1083px #fff,
        686px 1734px #fff, 467px 1011px #fff, 1442px 1071px #fff,
        1761px 1939px #fff, 1130px 874px #fff, 609px 1890px #fff,
        1568px 1108px #fff, 865px 1555px #fff, 1773px 769px #fff,
        441px 717px #fff, 240px 1701px #fff, 1108px 72px #fff, 1093px 235px #fff,
        241px 1500px #fff, 1760px 268px #fff, 339px 1676px #fff,
        1382px 1189px #fff, 1549px 84px #fff, 726px 327px #fff,
        396px 1074px #fff, 1673px 913px #fff, 66px 804px #fff,
        1619px 1295px #fff, 1160px 1963px #fff, 228px 788px #fff,
        288px 193px #fff, 1926px 1737px #fff, 1606px 1365px #fff,
        1240px 1086px #fff, 478px 667px #fff, 58px 1467px #fff,
        1686px 137px #fff, 1586px 819px #fff, 128px 326px #fff,
        1218px 1090px #fff, 20px 735px #fff, 839px 499px #fff, 508px 1045px #fff,
        285px 1783px #fff, 1040px 215px #fff, 1257px 840px #fff,
        682px 1409px #fff, 127px 1370px #fff, 933px 55px #fff, 1760px 622px #fff,
        1249px 657px #fff, 505px 1752px #fff, 176px 951px #fff,
        1258px 1542px #fff, 476px 1625px #fff, 254px 1105px #fff,
        463px 1082px #fff, 1159px 561px #fff, 261px 478px #fff,
        1034px 1085px #fff, 601px 472px #fff, 698px 315px #fff, 173px 667px #fff,
        249px 78px #fff, 104px 1523px #fff, 426px 140px #fff, 1442px 1499px #fff,
        1016px 900px #fff, 975px 1484px #fff, 1762px 144px #fff,
        1085px 403px #fff, 1023px 743px #fff, 543px 1786px #fff,
        1592px 1676px #fff, 1520px 346px #fff, 1974px 1002px #fff,
        1399px 477px #fff, 1570px 426px #fff, 1082px 1894px #fff,
        749px 244px #fff, 1728px 151px #fff, 1694px 1905px #fff,
        1470px 1675px #fff, 1436px 564px #fff, 1217px 53px #fff, 926px 39px #fff,
        1225px 1828px #fff, 1268px 484px #fff, 791px 1986px #fff,
        407px 1276px #fff, 507px 1972px #fff, 443px 224px #fff, 785px 898px #fff,
        1121px 661px #fff, 387px 989px #fff, 207px 1378px #fff,
        730px 1388px #fff, 1514px 330px #fff, 602px 90px #fff, 451px 1462px #fff,
        438px 165px #fff, 1097px 1309px #fff, 1136px 1898px #fff,
        638px 1114px #fff, 1073px 1206px #fff, 1618px 734px #fff,
        1517px 1692px #fff, 1630px 382px #fff, 1229px 546px #fff,
        1872px 444px #fff, 1559px 359px #fff, 1441px 838px #fff,
        857px 1825px #fff, 1584px 886px #fff, 174px 111px #fff,
        158px 1156px #fff, 1083px 899px #fff, 662px 123px #fff,
        1277px 484px #fff, 1797px 431px #fff, 1521px 1922px #fff,
        1770px 1191px #fff, 1627px 1807px #fff, 1977px 422px #fff,
        344px 1510px #fff, 1509px 1811px #fff, 1838px 1914px #fff,
        717px 1949px #fff, 1042px 1034px #fff, 176px 1097px #fff,
        903px 318px #fff, 1129px 2px #fff, 1410px 496px #fff, 835px 818px #fff,
        1228px 1564px #fff, 1981px 86px #fff, 1797px 1490px #fff,
        1635px 453px #fff, 1608px 1125px #fff, 1005px 1746px #fff,
        1212px 506px #fff;
    animation: animStar 50s linear infinite;
}
.stars:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1815px 592px #fff, 719px 1232px #fff, 836px 1251px #fff,
        1752px 515px #fff, 1137px 919px #fff, 1864px 558px #fff, 95px 144px #fff,
        1205px 296px #fff, 977px 675px #fff, 206px 38px #fff, 1909px 893px #fff,
        1650px 504px #fff, 1466px 956px #fff, 1627px 1361px #fff,
        1376px 1667px #fff, 1245px 1150px #fff, 1993px 1023px #fff,
        1639px 645px #fff, 1042px 1572px #fff, 373px 1976px #fff,
        823px 1668px #fff, 1790px 1988px #fff, 1892px 1592px #fff,
        542px 1557px #fff, 1084px 1577px #fff, 1421px 1657px #fff,
        85px 378px #fff, 1143px 1831px #fff, 963px 1317px #fff, 387px 325px #fff,
        44px 1715px #fff, 1324px 1639px #fff, 405px 726px #fff,
        1900px 1864px #fff, 1009px 55px #fff, 1442px 1228px #fff,
        394px 1710px #fff, 481px 758px #fff, 1204px 1331px #fff,
        1704px 1134px #fff, 210px 1081px #fff, 462px 58px #fff,
        1365px 403px #fff, 765px 207px #fff, 1974px 167px #fff,
        1264px 993px #fff, 1197px 1851px #fff, 84px 688px #fff,
        507px 1706px #fff, 1127px 1103px #fff, 295px 1225px #fff,
        1009px 797px #fff, 1598px 1718px #fff, 1581px 1749px #fff,
        1998px 710px #fff, 221px 73px #fff, 1717px 400px #fff, 769px 591px #fff,
        1319px 1289px #fff, 578px 339px #fff, 281px 1489px #fff,
        87px 1909px #fff, 145px 1232px #fff, 1857px 1504px #fff,
        1529px 1363px #fff, 48px 711px #fff, 622px 1542px #fff,
        1736px 152px #fff, 1033px 355px #fff, 539px 1073px #fff,
        852px 162px #fff, 1506px 546px #fff, 793px 1743px #fff,
        1472px 333px #fff, 987px 1466px #fff, 637px 1457px #fff,
        66px 1885px #fff, 980px 234px #fff, 1804px 1651px #fff,
        1972px 1916px #fff, 971px 1638px #fff, 1109px 462px #fff,
        1764px 799px #fff, 267px 1768px #fff, 778px 1260px #fff,
        1271px 1010px #fff, 1903px 1905px #fff, 197px 290px #fff,
        772px 1199px #fff, 535px 75px #fff, 1802px 79px #fff, 249px 730px #fff,
        1887px 896px #fff, 1612px 435px #fff, 1811px 1635px #fff,
        1389px 1434px #fff, 951px 933px #fff, 1037px 958px #fff,
        237px 355px #fff, 1385px 345px #fff, 1237px 985px #fff,
        639px 1439px #fff, 992px 1353px #fff, 1946px 358px #fff,
        481px 1623px #fff, 1749px 498px #fff, 529px 217px #fff,
        1056px 717px #fff, 1140px 903px #fff, 1983px 1891px #fff,
        1143px 812px #fff, 1111px 253px #fff, 1396px 852px #fff,
        1465px 1541px #fff, 1727px 486px #fff, 26px 1906px #fff,
        134px 1240px #fff, 269px 1664px #fff, 187px 515px #fff,
        1643px 1993px #fff, 547px 211px #fff, 624px 1386px #fff,
        1451px 447px #fff, 1157px 718px #fff, 114px 1909px #fff,
        1863px 833px #fff, 1493px 1141px #fff, 1228px 400px #fff,
        1853px 673px #fff, 1241px 854px #fff, 1154px 859px #fff,
        1727px 245px #fff, 1082px 1222px #fff, 1300px 758px #fff,
        1486px 1768px #fff, 1576px 353px #fff, 819px 1479px #fff,
        1994px 1171px #fff, 1944px 1037px #fff, 282px 1459px #fff,
        1051px 1524px #fff, 897px 1364px #fff, 796px 1444px #fff,
        644px 1442px #fff, 1464px 369px #fff, 1212px 1435px #fff,
        632px 806px #fff, 956px 1534px #fff, 1694px 1701px #fff,
        1562px 478px #fff, 577px 1966px #fff, 558px 1728px #fff,
        1937px 1472px #fff, 57px 1661px #fff, 761px 1981px #fff,
        1845px 1716px #fff, 1537px 1877px #fff, 1465px 417px #fff,
        1300px 1768px #fff, 126px 386px #fff, 1234px 203px #fff,
        855px 760px #fff, 1925px 333px #fff, 1293px 1861px #fff,
        839px 1289px #fff, 1590px 94px #fff, 1558px 723px #fff,
        1492px 974px #fff, 289px 1745px #fff, 1322px 205px #fff,
        1981px 27px #fff, 1685px 131px #fff, 1757px 912px #fff,
        1557px 899px #fff, 1256px 1764px #fff, 1740px 319px #fff,
        1219px 1828px #fff, 1734px 490px #fff, 1560px 1688px #fff,
        858px 700px #fff, 728px 809px #fff, 1837px 1142px #fff,
        642px 1589px #fff, 1535px 1302px #fff, 1185px 1211px #fff,
        952px 1950px #fff, 1227px 692px #fff, 240px 1751px #fff,
        1940px 714px #fff, 1249px 773px #fff, 919px 169px #fff,
        1632px 423px #fff, 1857px 1245px #fff, 1569px 686px #fff,
        1711px 1188px #fff, 1368px 898px #fff, 1361px 1762px #fff,
        1945px 900px #fff, 1446px 1396px #fff, 554px 1427px #fff,
        303px 1997px #fff, 179px 342px #fff, 1048px 732px #fff,
        867px 1236px #fff, 1240px 349px #fff, 1790px 421px #fff,
        189px 1990px #fff, 1140px 1602px #fff, 37px 593px #fff, 247px 869px #fff,
        1598px 463px #fff, 204px 516px #fff, 779px 1131px #fff,
        666px 1105px #fff, 581px 559px #fff, 1183px 660px #fff,
        1824px 1208px #fff, 1174px 909px #fff, 1293px 1802px #fff,
        397px 401px #fff, 555px 1168px #fff, 382px 1827px #fff,
        1037px 1024px #fff, 1246px 515px #fff, 298px 67px #fff, 1511px 79px #fff,
        1033px 753px #fff, 1897px 1083px #fff, 293px 1957px #fff,
        78px 1465px #fff, 1098px 876px #fff, 909px 1821px #fff, 591px 674px #fff,
        1926px 788px #fff, 1750px 1742px #fff, 1517px 95px #fff,
        1074px 593px #fff, 1907px 1394px #fff, 946px 700px #fff,
        1256px 1785px #fff, 302px 1398px #fff, 1298px 1667px #fff,
        1031px 933px #fff, 58px 671px #fff, 6px 1826px #fff, 613px 1785px #fff,
        951px 155px #fff, 855px 684px #fff, 593px 1940px #fff,
        1898px 1147px #fff, 573px 287px #fff, 321px 1629px #fff,
        1374px 529px #fff, 1486px 34px #fff, 1160px 546px #fff,
        567px 1892px #fff, 570px 441px #fff, 1857px 1270px #fff,
        297px 827px #fff, 1087px 1416px #fff, 687px 1487px #fff,
        188px 1530px #fff, 605px 467px #fff, 1769px 1416px #fff,
        120px 1609px #fff, 192px 1199px #fff, 314px 1065px #fff,
        294px 1191px #fff, 1265px 130px #fff, 516px 407px #fff, 58px 1703px #fff,
        1078px 1689px #fff, 1495px 317px #fff, 825px 1996px #fff,
        387px 518px #fff, 469px 1405px #fff, 792px 1577px #fff, 261px 642px #fff,
        500px 195px #fff, 1323px 1282px #fff, 1843px 394px #fff,
        1419px 1170px #fff, 1166px 203px #fff, 1392px 722px #fff,
        932px 1462px #fff, 767px 193px #fff, 1998px 105px #fff, 1005px 7px #fff,
        1620px 1138px #fff, 1156px 1622px #fff, 1304px 904px #fff,
        1984px 1852px #fff, 1767px 1971px #fff, 1391px 413px #fff,
        954px 1522px #fff, 937px 1604px #fff, 942px 211px #fff,
        1950px 1960px #fff, 1127px 538px #fff, 1446px 1923px #fff,
        1009px 806px #fff, 281px 544px #fff, 1916px 414px #fff,
        871px 1534px #fff, 1033px 12px #fff, 1549px 1130px #fff,
        1868px 224px #fff, 1281px 1930px #fff, 1996px 1433px #fff,
        1998px 1135px #fff, 1301px 627px #fff, 632px 828px #fff, 1588px 7px #fff,
        543px 1437px #fff, 403px 1421px #fff, 1835px 814px #fff,
        356px 1716px #fff, 1383px 1445px #fff, 1922px 1330px #fff,
        1340px 986px #fff, 1345px 340px #fff, 1199px 528px #fff,
        1718px 305px #fff, 339px 1034px #fff, 514px 1448px #fff,
        248px 1532px #fff, 1349px 825px #fff, 1041px 1365px #fff,
        1792px 1674px #fff, 1818px 1132px #fff, 1359px 951px #fff,
        1500px 92px #fff, 101px 2px #fff, 1755px 463px #fff, 1959px 1072px #fff,
        193px 345px #fff, 77px 314px #fff, 1534px 39px #fff, 762px 1453px #fff,
        1786px 1963px #fff, 1888px 1454px #fff, 839px 1783px #fff,
        846px 1916px #fff, 323px 266px #fff, 245px 889px #fff, 939px 1342px #fff,
        1925px 532px #fff, 873px 878px #fff, 371px 761px #fff, 900px 1064px #fff,
        1552px 209px #fff, 794px 1515px #fff, 887px 1483px #fff,
        899px 278px #fff, 1902px 383px #fff, 847px 139px #fff, 219px 338px #fff,
        1564px 511px #fff, 25px 537px #fff, 720px 391px #fff, 1019px 552px #fff,
        1611px 1636px #fff, 834px 1925px #fff, 1902px 490px #fff,
        1562px 325px #fff, 1245px 23px #fff, 304px 1467px #fff,
        441px 1584px #fff, 468px 88px #fff, 1250px 873px #fff, 1771px 918px #fff,
        1928px 492px #fff, 1899px 85px #fff, 831px 1590px #fff,
        1470px 311px #fff, 317px 418px #fff, 1781px 637px #fff,
        1441px 1545px #fff, 1385px 1152px #fff, 137px 226px #fff,
        1104px 1839px #fff, 1076px 44px #fff, 67px 1540px #fff,
        1388px 1625px #fff, 1850px 1400px #fff, 708px 286px #fff, 280px 7px #fff,
        515px 534px #fff, 1130px 337px #fff, 1869px 1559px #fff,
        1014px 1350px #fff, 1295px 956px #fff, 145px 1945px #fff,
        137px 1566px #fff, 589px 782px #fff, 1162px 1732px #fff,
        1795px 1518px #fff, 1705px 1667px #fff, 1185px 734px #fff,
        81px 31px #fff, 1526px 822px #fff, 1547px 983px #fff, 1317px 1283px #fff,
        403px 817px #fff, 1506px 1198px #fff, 1899px 1540px #fff,
        1390px 1561px #fff, 380px 767px #fff, 1110px 247px #fff,
        1078px 475px #fff, 1120px 473px #fff, 1579px 72px #fff,
        976px 1164px #fff, 1634px 1263px #fff, 1648px 519px #fff,
        1437px 1568px #fff, 1019px 203px #fff, 1385px 147px #fff,
        1975px 215px #fff, 20px 162px #fff, 646px 1998px #fff, 1908px 710px #fff,
        390px 1727px #fff, 570px 546px #fff, 34px 1991px #fff, 1037px 573px #fff,
        1585px 512px #fff, 210px 584px #fff, 606px 207px #fff,
        1385px 1082px #fff, 659px 417px #fff, 1117px 583px #fff,
        1553px 129px #fff, 1886px 1738px #fff, 1108px 1426px #fff,
        1774px 1711px #fff, 626px 1366px #fff, 881px 1836px #fff,
        5px 1096px #fff, 424px 785px #fff, 25px 261px #fff, 547px 1505px #fff,
        953px 870px #fff, 1202px 1490px #fff, 1525px 1401px #fff,
        506px 1443px #fff, 99px 633px #fff, 115px 248px #fff, 1378px 1054px #fff,
        1423px 211px #fff, 120px 1861px #fff, 1395px 338px #fff,
        794px 496px #fff, 1367px 1288px #fff, 492px 1555px #fff, 2px 1498px #fff,
        1186px 358px #fff, 236px 902px #fff, 1790px 534px #fff, 684px 376px #fff,
        605px 1824px #fff, 1192px 1220px #fff, 1565px 645px #fff,
        1933px 95px #fff, 173px 1131px #fff, 1461px 1572px #fff,
        1457px 1105px #fff, 1365px 1020px #fff, 1946px 130px #fff,
        1019px 1197px #fff, 898px 749px #fff, 1929px 852px #fff,
        1665px 703px #fff, 1086px 1684px #fff, 630px 368px #fff,
        832px 1653px #fff, 304px 291px #fff, 86px 69px #fff, 1615px 464px #fff,
        834px 1204px #fff, 1453px 1609px #fff, 62px 1831px #fff,
        1051px 431px #fff, 499px 1633px #fff, 472px 1584px #fff,
        744px 719px #fff, 1989px 155px #fff, 322px 1614px #fff,
        1957px 1370px #fff, 1726px 662px #fff, 687px 894px #fff,
        1385px 927px #fff, 1929px 315px #fff, 699px 1733px #fff,
        178px 1633px #fff, 1955px 212px #fff, 195px 1754px #fff,
        1057px 1419px #fff, 1018px 1547px #fff, 1322px 1017px #fff,
        540px 1397px #fff, 385px 694px #fff, 1024px 1011px #fff,
        1251px 384px #fff, 94px 1833px #fff, 1441px 800px #fff, 911px 351px #fff,
        1688px 504px #fff, 80px 826px #fff, 1709px 643px #fff,
        1784px 1736px #fff, 1802px 1162px #fff, 206px 1467px #fff,
        522px 1670px #fff, 108px 1445px #fff, 1931px 169px #fff,
        812px 1897px #fff, 1083px 1799px #fff, 340px 933px #fff,
        287px 1284px #fff, 573px 768px #fff, 1994px 1529px #fff,
        387px 971px #fff, 1456px 671px #fff, 1808px 1867px #fff,
        1580px 117px #fff, 129px 967px #fff, 1237px 108px #fff, 169px 884px #fff,
        257px 537px #fff, 1837px 1264px #fff, 34px 968px #fff, 375px 1511px #fff,
        882px 813px #fff, 1681px 1326px #fff, 456px 598px #fff,
        654px 1647px #fff, 929px 757px #fff, 913px 1508px #fff,
        1222px 795px #fff, 1336px 1896px #fff, 1018px 296px #fff,
        1942px 513px #fff, 272px 1524px #fff, 370px 623px #fff,
        1744px 1014px #fff, 74px 1007px #fff, 496px 506px #fff,
        1434px 1434px #fff, 1920px 1557px #fff, 1912px 695px #fff,
        1747px 1907px #fff, 640px 1508px #fff, 300px 1225px #fff,
        984px 1676px #fff, 385px 565px #fff, 523px 1613px #fff, 15px 1269px #fff,
        1757px 1478px #fff, 1534px 1065px #fff, 752px 1936px #fff,
        1269px 518px #fff, 759px 464px #fff, 560px 1083px #fff,
        686px 1734px #fff, 467px 1011px #fff, 1442px 1071px #fff,
        1761px 1939px #fff, 1130px 874px #fff, 609px 1890px #fff,
        1568px 1108px #fff, 865px 1555px #fff, 1773px 769px #fff,
        441px 717px #fff, 240px 1701px #fff, 1108px 72px #fff, 1093px 235px #fff,
        241px 1500px #fff, 1760px 268px #fff, 339px 1676px #fff,
        1382px 1189px #fff, 1549px 84px #fff, 726px 327px #fff,
        396px 1074px #fff, 1673px 913px #fff, 66px 804px #fff,
        1619px 1295px #fff, 1160px 1963px #fff, 228px 788px #fff,
        288px 193px #fff, 1926px 1737px #fff, 1606px 1365px #fff,
        1240px 1086px #fff, 478px 667px #fff, 58px 1467px #fff,
        1686px 137px #fff, 1586px 819px #fff, 128px 326px #fff,
        1218px 1090px #fff, 20px 735px #fff, 839px 499px #fff, 508px 1045px #fff,
        285px 1783px #fff, 1040px 215px #fff, 1257px 840px #fff,
        682px 1409px #fff, 127px 1370px #fff, 933px 55px #fff, 1760px 622px #fff,
        1249px 657px #fff, 505px 1752px #fff, 176px 951px #fff,
        1258px 1542px #fff, 476px 1625px #fff, 254px 1105px #fff,
        463px 1082px #fff, 1159px 561px #fff, 261px 478px #fff,
        1034px 1085px #fff, 601px 472px #fff, 698px 315px #fff, 173px 667px #fff,
        249px 78px #fff, 104px 1523px #fff, 426px 140px #fff, 1442px 1499px #fff,
        1016px 900px #fff, 975px 1484px #fff, 1762px 144px #fff,
        1085px 403px #fff, 1023px 743px #fff, 543px 1786px #fff,
        1592px 1676px #fff, 1520px 346px #fff, 1974px 1002px #fff,
        1399px 477px #fff, 1570px 426px #fff, 1082px 1894px #fff,
        749px 244px #fff, 1728px 151px #fff, 1694px 1905px #fff,
        1470px 1675px #fff, 1436px 564px #fff, 1217px 53px #fff, 926px 39px #fff,
        1225px 1828px #fff, 1268px 484px #fff, 791px 1986px #fff,
        407px 1276px #fff, 507px 1972px #fff, 443px 224px #fff, 785px 898px #fff,
        1121px 661px #fff, 387px 989px #fff, 207px 1378px #fff,
        730px 1388px #fff, 1514px 330px #fff, 602px 90px #fff, 451px 1462px #fff,
        438px 165px #fff, 1097px 1309px #fff, 1136px 1898px #fff,
        638px 1114px #fff, 1073px 1206px #fff, 1618px 734px #fff,
        1517px 1692px #fff, 1630px 382px #fff, 1229px 546px #fff,
        1872px 444px #fff, 1559px 359px #fff, 1441px 838px #fff,
        857px 1825px #fff, 1584px 886px #fff, 174px 111px #fff,
        158px 1156px #fff, 1083px 899px #fff, 662px 123px #fff,
        1277px 484px #fff, 1797px 431px #fff, 1521px 1922px #fff,
        1770px 1191px #fff, 1627px 1807px #fff, 1977px 422px #fff,
        344px 1510px #fff, 1509px 1811px #fff, 1838px 1914px #fff,
        717px 1949px #fff, 1042px 1034px #fff, 176px 1097px #fff,
        903px 318px #fff, 1129px 2px #fff, 1410px 496px #fff, 835px 818px #fff,
        1228px 1564px #fff, 1981px 86px #fff, 1797px 1490px #fff,
        1635px 453px #fff, 1608px 1125px #fff, 1005px 1746px #fff,
        1212px 506px #fff;
}

.stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1563px 1164px #fff, 512px 1885px #fff, 1880px 690px #fff,
        748px 922px #fff, 202px 15px #fff, 968px 885px #fff, 1495px 1465px #fff,
        1959px 1298px #fff, 1262px 179px #fff, 1916px 447px #fff,
        915px 1493px #fff, 938px 1212px #fff, 319px 20px #fff, 934px 1702px #fff,
        514px 1025px #fff, 238px 1003px #fff, 1901px 1817px #fff,
        397px 587px #fff, 1334px 1123px #fff, 1438px 457px #fff,
        1612px 1708px #fff, 197px 1529px #fff, 1006px 8px #fff,
        415px 1773px #fff, 1193px 521px #fff, 1360px 1363px #fff,
        1084px 1865px #fff, 551px 1311px #fff, 1133px 408px #fff,
        1327px 708px #fff, 1481px 790px #fff, 1462px 827px #fff,
        1155px 320px #fff, 345px 1504px #fff, 234px 1204px #fff,
        388px 446px #fff, 1342px 1227px #fff, 1857px 1530px #fff,
        717px 989px #fff, 1523px 987px #fff, 1593px 1883px #fff,
        1754px 318px #fff, 1295px 806px #fff, 1816px 1270px #fff,
        138px 1361px #fff, 1544px 450px #fff, 477px 1445px #fff,
        1848px 1417px #fff, 1718px 140px #fff, 1269px 1624px #fff,
        914px 851px #fff, 1840px 1270px #fff, 903px 1214px #fff,
        1569px 597px #fff, 1783px 1351px #fff, 387px 1745px #fff,
        108px 1033px #fff, 1189px 53px #fff, 380px 569px #fff, 61px 1913px #fff,
        1419px 1109px #fff, 1651px 460px #fff, 1501px 565px #fff,
        561px 1983px #fff, 149px 991px #fff, 244px 1362px #fff,
        1288px 318px #fff, 177px 1784px #fff, 506px 1365px #fff,
        1402px 324px #fff, 1920px 237px #fff, 1998px 1192px #fff,
        732px 622px #fff, 1140px 1339px #fff, 1829px 1937px #fff, 30px 64px #fff,
        1718px 442px #fff, 1839px 61px #fff, 522px 1664px #fff,
        1539px 703px #fff, 369px 906px #fff, 1647px 1823px #fff,
        1166px 10px #fff, 1504px 732px #fff, 1296px 185px #fff,
        1947px 834px #fff, 1746px 1068px #fff, 1737px 101px #fff,
        1743px 248px #fff, 1854px 1846px #fff, 686px 1061px #fff,
        525px 1846px #fff, 717px 1125px #fff, 361px 879px #fff, 170px 676px #fff,
        1417px 711px #fff, 168px 1238px #fff, 434px 744px #fff,
        1431px 1735px #fff, 964px 195px #fff, 516px 1476px #fff,
        1007px 189px #fff, 598px 62px #fff, 602px 1540px #fff, 199px 601px #fff,
        327px 1877px #fff, 1022px 1156px #fff, 104px 188px #fff,
        1818px 1932px #fff, 1289px 240px #fff, 284px 295px #fff,
        1662px 379px #fff, 1486px 1655px #fff, 891px 480px #fff,
        774px 1009px #fff, 707px 849px #fff, 1633px 263px #fff,
        484px 1981px #fff, 796px 1723px #fff, 423px 232px #fff,
        1037px 1594px #fff, 1932px 160px #fff, 1850px 1714px #fff,
        991px 975px #fff, 1499px 120px #fff, 356px 298px #fff, 126px 999px #fff,
        1433px 59px #fff, 1645px 1773px #fff, 1709px 891px #fff,
        310px 633px #fff, 1339px 763px #fff, 1858px 1339px #fff,
        295px 1480px #fff, 1476px 1337px #fff, 297px 611px #fff,
        1474px 1318px #fff, 244px 1389px #fff, 1480px 133px #fff,
        1486px 841px #fff, 1534px 1985px #fff, 536px 124px #fff,
        1869px 1869px #fff, 980px 1621px #fff, 1871px 814px #fff,
        685px 1354px #fff, 1949px 1520px #fff, 1313px 292px #fff,
        1644px 110px #fff, 1952px 1071px #fff, 1533px 936px #fff,
        741px 1991px #fff, 1006px 773px #fff, 1367px 374px #fff,
        1589px 1850px #fff, 878px 1552px #fff, 362px 1441px #fff,
        411px 499px #fff, 400px 298px #fff, 700px 1131px #fff, 828px 277px #fff,
        871px 139px #fff, 222px 400px #fff, 769px 1914px #fff, 919px 175px #fff,
        1692px 1624px #fff, 1843px 285px #fff, 1940px 917px #fff,
        226px 94px #fff, 709px 1247px #fff, 1575px 745px #fff,
        1487px 1162px #fff, 1059px 788px #fff, 526px 219px #fff,
        1403px 1161px #fff, 790px 420px #fff, 1382px 566px #fff,
        599px 1247px #fff, 674px 1826px #fff, 158px 1564px #fff,
        743px 258px #fff, 96px 53px #fff, 752px 93px #fff, 1566px 774px #fff,
        708px 207px #fff, 1854px 1851px #fff, 88px 1888px #fff,
        1768px 1313px #fff, 1538px 1133px #fff, 1928px 1977px #fff,
        1227px 1527px #fff, 1804px 832px #fff, 968px 196px #fff,
        373px 1457px #fff, 1037px 1350px #fff, 1166px 1829px #fff,
        1356px 1088px #fff, 247px 1142px #fff, 364px 1684px #fff,
        250px 1394px #fff;
    animation: animStar 100s linear infinite;
}
.stars2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1563px 1164px #fff, 512px 1885px #fff, 1880px 690px #fff,
        748px 922px #fff, 202px 15px #fff, 968px 885px #fff, 1495px 1465px #fff,
        1959px 1298px #fff, 1262px 179px #fff, 1916px 447px #fff,
        915px 1493px #fff, 938px 1212px #fff, 319px 20px #fff, 934px 1702px #fff,
        514px 1025px #fff, 238px 1003px #fff, 1901px 1817px #fff,
        397px 587px #fff, 1334px 1123px #fff, 1438px 457px #fff,
        1612px 1708px #fff, 197px 1529px #fff, 1006px 8px #fff,
        415px 1773px #fff, 1193px 521px #fff, 1360px 1363px #fff,
        1084px 1865px #fff, 551px 1311px #fff, 1133px 408px #fff,
        1327px 708px #fff, 1481px 790px #fff, 1462px 827px #fff,
        1155px 320px #fff, 345px 1504px #fff, 234px 1204px #fff,
        388px 446px #fff, 1342px 1227px #fff, 1857px 1530px #fff,
        717px 989px #fff, 1523px 987px #fff, 1593px 1883px #fff,
        1754px 318px #fff, 1295px 806px #fff, 1816px 1270px #fff,
        138px 1361px #fff, 1544px 450px #fff, 477px 1445px #fff,
        1848px 1417px #fff, 1718px 140px #fff, 1269px 1624px #fff,
        914px 851px #fff, 1840px 1270px #fff, 903px 1214px #fff,
        1569px 597px #fff, 1783px 1351px #fff, 387px 1745px #fff,
        108px 1033px #fff, 1189px 53px #fff, 380px 569px #fff, 61px 1913px #fff,
        1419px 1109px #fff, 1651px 460px #fff, 1501px 565px #fff,
        561px 1983px #fff, 149px 991px #fff, 244px 1362px #fff,
        1288px 318px #fff, 177px 1784px #fff, 506px 1365px #fff,
        1402px 324px #fff, 1920px 237px #fff, 1998px 1192px #fff,
        732px 622px #fff, 1140px 1339px #fff, 1829px 1937px #fff, 30px 64px #fff,
        1718px 442px #fff, 1839px 61px #fff, 522px 1664px #fff,
        1539px 703px #fff, 369px 906px #fff, 1647px 1823px #fff,
        1166px 10px #fff, 1504px 732px #fff, 1296px 185px #fff,
        1947px 834px #fff, 1746px 1068px #fff, 1737px 101px #fff,
        1743px 248px #fff, 1854px 1846px #fff, 686px 1061px #fff,
        525px 1846px #fff, 717px 1125px #fff, 361px 879px #fff, 170px 676px #fff,
        1417px 711px #fff, 168px 1238px #fff, 434px 744px #fff,
        1431px 1735px #fff, 964px 195px #fff, 516px 1476px #fff,
        1007px 189px #fff, 598px 62px #fff, 602px 1540px #fff, 199px 601px #fff,
        327px 1877px #fff, 1022px 1156px #fff, 104px 188px #fff,
        1818px 1932px #fff, 1289px 240px #fff, 284px 295px #fff,
        1662px 379px #fff, 1486px 1655px #fff, 891px 480px #fff,
        774px 1009px #fff, 707px 849px #fff, 1633px 263px #fff,
        484px 1981px #fff, 796px 1723px #fff, 423px 232px #fff,
        1037px 1594px #fff, 1932px 160px #fff, 1850px 1714px #fff,
        991px 975px #fff, 1499px 120px #fff, 356px 298px #fff, 126px 999px #fff,
        1433px 59px #fff, 1645px 1773px #fff, 1709px 891px #fff,
        310px 633px #fff, 1339px 763px #fff, 1858px 1339px #fff,
        295px 1480px #fff, 1476px 1337px #fff, 297px 611px #fff,
        1474px 1318px #fff, 244px 1389px #fff, 1480px 133px #fff,
        1486px 841px #fff, 1534px 1985px #fff, 536px 124px #fff,
        1869px 1869px #fff, 980px 1621px #fff, 1871px 814px #fff,
        685px 1354px #fff, 1949px 1520px #fff, 1313px 292px #fff,
        1644px 110px #fff, 1952px 1071px #fff, 1533px 936px #fff,
        741px 1991px #fff, 1006px 773px #fff, 1367px 374px #fff,
        1589px 1850px #fff, 878px 1552px #fff, 362px 1441px #fff,
        411px 499px #fff, 400px 298px #fff, 700px 1131px #fff, 828px 277px #fff,
        871px 139px #fff, 222px 400px #fff, 769px 1914px #fff, 919px 175px #fff,
        1692px 1624px #fff, 1843px 285px #fff, 1940px 917px #fff,
        226px 94px #fff, 709px 1247px #fff, 1575px 745px #fff,
        1487px 1162px #fff, 1059px 788px #fff, 526px 219px #fff,
        1403px 1161px #fff, 790px 420px #fff, 1382px 566px #fff,
        599px 1247px #fff, 674px 1826px #fff, 158px 1564px #fff,
        743px 258px #fff, 96px 53px #fff, 752px 93px #fff, 1566px 774px #fff,
        708px 207px #fff, 1854px 1851px #fff, 88px 1888px #fff,
        1768px 1313px #fff, 1538px 1133px #fff, 1928px 1977px #fff,
        1227px 1527px #fff, 1804px 832px #fff, 968px 196px #fff,
        373px 1457px #fff, 1037px 1350px #fff, 1166px 1829px #fff,
        1356px 1088px #fff, 247px 1142px #fff, 364px 1684px #fff,
        250px 1394px #fff;
}

.stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1448px 1212px #fff, 903px 1693px #fff, 386px 749px #fff,
        1607px 400px #fff, 1269px 273px #fff, 17px 1502px #fff, 99px 1848px #fff,
        1513px 1441px #fff, 674px 725px #fff, 1090px 127px #fff,
        1140px 1154px #fff, 984px 470px #fff, 945px 1284px #fff,
        1166px 218px #fff, 398px 269px #fff, 1779px 42px #fff, 644px 746px #fff,
        1692px 442px #fff, 675px 241px #fff, 1824px 1284px #fff,
        382px 775px #fff, 1895px 1958px #fff, 1857px 853px #fff,
        257px 1271px #fff, 393px 1741px #fff, 673px 1507px #fff, 82px 549px #fff,
        1611px 837px #fff, 1042px 1401px #fff, 813px 1402px #fff,
        1283px 1299px #fff, 835px 1543px #fff, 1838px 1578px #fff,
        1059px 598px #fff, 609px 1034px #fff, 1491px 387px #fff,
        1658px 397px #fff, 1261px 530px #fff, 728px 544px #fff, 912px 11px #fff,
        90px 394px #fff, 120px 580px #fff, 56px 404px #fff, 782px 1187px #fff,
        307px 1335px #fff, 1548px 486px #fff, 829px 1971px #fff,
        1717px 913px #fff, 278px 1016px #fff, 1605px 826px #fff,
        940px 1411px #fff, 56px 1229px #fff, 1155px 114px #fff,
        1634px 1773px #fff, 61px 27px #fff, 114px 1988px #fff,
        1682px 1476px #fff, 1390px 843px #fff, 1723px 1811px #fff,
        722px 998px #fff, 683px 309px #fff, 1239px 584px #fff, 1661px 47px #fff,
        992px 215px #fff, 769px 1640px #fff, 1075px 878px #fff,
        1301px 1944px #fff, 1277px 1107px #fff, 1780px 461px #fff,
        565px 801px #fff, 1463px 1830px #fff, 536px 1974px #fff, 64px 570px #fff,
        100px 26px #fff, 1398px 640px #fff, 98px 1093px #fff, 674px 1225px #fff,
        808px 1660px #fff, 1801px 951px #fff, 673px 846px #fff,
        1687px 1082px #fff, 1969px 546px #fff, 61px 596px #fff,
        1502px 199px #fff, 1591px 54px #fff, 928px 299px #fff, 5px 641px #fff,
        232px 228px #fff, 14px 1724px #fff, 88px 841px #fff, 1215px 1335px #fff,
        1219px 1656px #fff, 865px 966px #fff, 1783px 1227px #fff,
        1898px 1374px #fff, 433px 1385px #fff, 963px 1234px #fff,
        1195px 886px #fff, 90px 1475px #fff, 1258px 936px #fff;
    animation: animStar 150s linear infinite;
}
.stars3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1448px 1212px #fff, 903px 1693px #fff, 386px 749px #fff,
        1607px 400px #fff, 1269px 273px #fff, 17px 1502px #fff, 99px 1848px #fff,
        1513px 1441px #fff, 674px 725px #fff, 1090px 127px #fff,
        1140px 1154px #fff, 984px 470px #fff, 945px 1284px #fff,
        1166px 218px #fff, 398px 269px #fff, 1779px 42px #fff, 644px 746px #fff,
        1692px 442px #fff, 675px 241px #fff, 1824px 1284px #fff,
        382px 775px #fff, 1895px 1958px #fff, 1857px 853px #fff,
        257px 1271px #fff, 393px 1741px #fff, 673px 1507px #fff, 82px 549px #fff,
        1611px 837px #fff, 1042px 1401px #fff, 813px 1402px #fff,
        1283px 1299px #fff, 835px 1543px #fff, 1838px 1578px #fff,
        1059px 598px #fff, 609px 1034px #fff, 1491px 387px #fff,
        1658px 397px #fff, 1261px 530px #fff, 728px 544px #fff, 912px 11px #fff,
        90px 394px #fff, 120px 580px #fff, 56px 404px #fff, 782px 1187px #fff,
        307px 1335px #fff, 1548px 486px #fff, 829px 1971px #fff,
        1717px 913px #fff, 278px 1016px #fff, 1605px 826px #fff,
        940px 1411px #fff, 56px 1229px #fff, 1155px 114px #fff,
        1634px 1773px #fff, 61px 27px #fff, 114px 1988px #fff,
        1682px 1476px #fff, 1390px 843px #fff, 1723px 1811px #fff,
        722px 998px #fff, 683px 309px #fff, 1239px 584px #fff, 1661px 47px #fff,
        992px 215px #fff, 769px 1640px #fff, 1075px 878px #fff,
        1301px 1944px #fff, 1277px 1107px #fff, 1780px 461px #fff,
        565px 801px #fff, 1463px 1830px #fff, 536px 1974px #fff, 64px 570px #fff,
        100px 26px #fff, 1398px 640px #fff, 98px 1093px #fff, 674px 1225px #fff,
        808px 1660px #fff, 1801px 951px #fff, 673px 846px #fff,
        1687px 1082px #fff, 1969px 546px #fff, 61px 596px #fff,
        1502px 199px #fff, 1591px 54px #fff, 928px 299px #fff, 5px 641px #fff,
        232px 228px #fff, 14px 1724px #fff, 88px 841px #fff, 1215px 1335px #fff,
        1219px 1656px #fff, 865px 966px #fff, 1783px 1227px #fff,
        1898px 1374px #fff, 433px 1385px #fff, 963px 1234px #fff,
        1195px 886px #fff, 90px 1475px #fff, 1258px 936px #fff;
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}
