html {
    scroll-behavior: smooth;
}

.navbar-transparent {
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    color: white;
}
.navbar-white {
    background-color: rgba(234, 236, 239, 0.81);
    transition: background-color 0.2s;
}
.text-brand {
    color: #363636 !important;
}

.socialicons {
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.4s;
    margin: 4px;
}
.socialicons:hover {
    color: #363636;
    transition: all 0.4s;
}

.text-white {
    color: white !important;
    transition: color 0.2s;
}

ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1.5rem;
}

/* Timeline vertical line */
ul.timeline:before {
    content: " ";
    background: lightgrey;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

li.timeline-item {
    margin: 20px 0;
}

/* Timeline item arrow */
.timeline-arrow {
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid #fff;
    border-bottom: 0.5rem solid transparent;
    display: block;
    position: absolute;
    left: 2rem;
}

/* Timeline item circle marker */
li.timeline-item::before {
    content: " ";
    background: #ddd;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #fff;
    left: 11px;
    width: 14px;
    height: 14px;
    z-index: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.text-gray {
    color: #999;
}

.email {
    color: #006cdf;
}

.cryptedmail:after {
    content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}

.lefttt .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}
.lefttt:hover .after {
    display: block;
    background: rgba(0, 0, 0,);
}