#mainbody {
    width: 100%;
    background-size: 300% 300%;
    background: linear-gradient(
            245deg,
            #4484ce,
            #1ad7c0,
            #ff9b11,
            #9b59b6,
            #ff7f7f,
            #ecf0f1
        )
        0% 0% / 1200% 1200%;
    animation: AnimateBG 20s ease infinite;
}

@keyframes AnimateBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
